// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-spinner .spinner {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 6px solid transparent;
  border-top-color: rgb(236, 114, 107);
  animation: spin 1s linear infinite, changeColor 6s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes changeColor {
  0% {
    border-top-color: rgb(236, 114, 107);
  }
  25% {
    border-top-color: rgb(63, 100, 119);
  }
  50% {
    border-top-color: rgb(76, 206, 196);
  }
  75% {
    border-top-color: rgb(255, 186, 93);
  }
  100% {
    border-top-color: rgb(254, 155, 150);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/spinner/LoadingSpinner.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,6BAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,6BAAA;EACA,oCAAA;EACA,kEAAA;AACJ;;AAGA;EACE;IACE,uBAAA;EAAF;EAEA;IACE,yBAAA;EAAF;AACF;AAGA;EACE;IACE,oCAAA;EADF;EAGA;IACE,mCAAA;EADF;EAGA;IACE,mCAAA;EADF;EAGA;IACE,mCAAA;EADF;EAGA;IACE,oCAAA;EADF;AACF","sourcesContent":[".loading-spinner {\r\n  position: fixed;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  z-index: 9999;\r\n  background-color: transparent;\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n\r\n  .spinner {\r\n    border-radius: 50%;\r\n    width: 50px;\r\n    height: 50px;\r\n    border: 6px solid transparent;\r\n    border-top-color: rgb(236, 114, 107);\r\n    animation: spin 1s linear infinite, changeColor 6s linear infinite;\r\n  }\r\n}\r\n\r\n@keyframes spin {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n\r\n@keyframes changeColor {\r\n  0% {\r\n    border-top-color: rgb(236, 114, 107);\r\n  }\r\n  25% {\r\n    border-top-color: rgb(63, 100, 119);\r\n  }\r\n  50% {\r\n    border-top-color: rgb(76, 206, 196);\r\n  }\r\n  75% {\r\n    border-top-color: rgb(255, 186, 93);\r\n  }\r\n  100% {\r\n    border-top-color: rgb(254, 155, 150);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
