import React, { Suspense } from 'react';
import AppRoutes from "./Routes/AppRoutes.jsx";
import logo from './logo.svg';
import "./assets/scss/style.scss";

import LoadingSpinner from "./components/spinner/LoadingSpinner.jsx";


function App() {
  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        <AppRoutes></AppRoutes>
      </Suspense>
    </>
  );
};

export default App;
